<template>
  <b-card>
    <b-card-header> Account deletion </b-card-header>
    <b-card-body>
      <div v-if="loading" class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
      <div v-if="!loading">
        <callback-panel
          v-if="message"
          title="Account deletion"
          :message="message"
          type="success"
          redirect-to="/"
          :buttonTitle="'Go to home page'"
        />

        <callback-panel
          v-if="errorMessage"
          title="Account deletion failed"
          :message="errorMessage"
          type="danger"
          redirect-to="/"
          :buttonTitle="'Go to home page'"
        />
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BCardHeader } from "bootstrap-vue";
import CallbackPanel from "@/layouts/components/CallbackPanel.vue";
import axios from "axios";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    CallbackPanel,
  },
  data() {
    const { verifyUrl = "" } = this.$route.query;
    return {
      verifyUrl,
      loading: false,
      message: "",
      errorMessage: "",
    };
  },
  mounted() {
    axios
      .get(this.verifyUrl)
      .then((response) => {
        this.message =
          response?.data?.message ??
          "Account deletion was successful, redirecting to home page.";
      })
      .catch((error) => {
        this.errorMessage =
          error?.response?.data?.message ?? "Account deletion failed.";
      })
      .finally(() => (this.loading = false));
  },
  methods: {},
};
</script>

<style></style>
